"use client";

import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  createTheme,
  type ThemeOptions,
} from "@mui/material/styles";
import React from "react";
import { roboto } from "../constants/fonts";
import { NextAppDirEmotionCacheProvider } from "./EmotionCache";

const themeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "scrollbarColor": "#444 #222",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#222",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#444",
            minHeight: 24,
            border: "3px solid #222",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#666",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#666",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#666",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#222",
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 10,
        },
      },
    },
  },

  typography: {
    fontSize: 12,
    fontFamily: roboto.style.fontFamily,
    body1: {
      fontSize: 16,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#3cc",
      contrastText: "#333",
    },
    secondary: {
      main: "#cc3",
      contrastText: "#333",
    },
    error: {
      main: "#c33",
      contrastText: "#333",
    },
    warning: {
      main: "#cc3",
      contrastText: "#333",
    },
    success: {
      main: "#3c3",
      contrastText: "#333",
    },
    info: {
      main: "#3cc",
      contrastText: "#333",
    },
    text: {
      primary: "#fff",
      secondary: "#ccc",
      disabled: "#999",
    },
    background: {
      default: "#333e",
      paper: "#333",
    },
    divider: "#3cc",
  },
};

const theme = createTheme(themeOptions);

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
